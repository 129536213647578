


// Quantity Plus Minus Jquery

jQuery(document).ready(function () {
  // This button will increment the value

 
  jQuery('.qtyplus').click(function (e) {
    // Stop acting like a button
    e.preventDefault();
    // Get the field name
    fieldName = jQuery(this).attr('field');
    // Get its current value
    var currentVal = parseInt(jQuery('input[name=' + fieldName + ']').val());
    // If is not undefined
    if (!isNaN(currentVal)) {
      // Increment
      jQuery('input[name=' + fieldName + ']').val(currentVal + 1);
    } else {
      // Otherwise put a 0 there
      jQuery('input[name=' + fieldName + ']').val(0);
    }
  });
  // This button will decrement the value till 0
  jQuery(".qtyminus").click(function (e) {
    // Stop acting like a button
    e.preventDefault();
    // Get the field name
    fieldName = jQuery(this).attr('field');
    // Get its current value
    var currentVal = parseInt($('input[name=' + fieldName + ']').val());
    // If it isn't undefined or its greater than 0
    if (!isNaN(currentVal) && currentVal > 0) {
      // Decrement one
      jQuery('input[name=' + fieldName + ']').val(currentVal - 1);
    } else {
      // Otherwise put a 0 there
      jQuery('input[name=' + fieldName + ']').val(0);
    }
  });

  jQuery('.form-control').click(function () {
    
    var modalDiv = jQuery('.modal-content');
    modalDiv.modal({ backdrop: false, show: true });

    jQuery('.modal-content').draggable({
      handle: ".modal-header"
    });
  });

  // $('#btn1').click(function() {
  //   var modalDiv = $('#myModal');
  //   modalDiv.modal({backdrop: false, show: true});
      
  //   $('.modal-dialog').draggable({
  //     handle: ".modal-header"
  //   });
  // });
});




var room = 1;
function education_fields() {

  room++;
  var objTo = document.getElementById('education_fields')
  var divtest = document.createElement("div");
  divtest.setAttribute("class", "row removeclass" + room);
  var rdiv = 'removeclass' + room;
  divtest.innerHTML = '<div class="col-sm-4"><div class="form-group"> <label>Item Name</label> <input type="text" class="form-control" value="" placeholder=""></div></div><div class="col-sm-2"><div class="form-group"><label>Quantity</label><input type="text" class="form-control" value="" placeholder=""></div></div><div class="col-sm-12 text-right"><div class="form-group"><button class="btn btn-danger" type="button" onclick="remove_education_fields(' + room + ');">Remove -</button></div></div>';

  objTo.appendChild(divtest)
}
function remove_education_fields(rid) {
  jQuery('.removeclass' + rid).remove();
}


function addonFunction() {
  // Get the checkbox
  var checkBox = document.getElementById("addon");
  // Get the output text
  var text = document.getElementById("addonbox");

  // If the checkbox is checked, display the output text
  if (checkBox.checked == true) {
    text.style.display = "block";
  } else {
    text.style.display = "none";
  }
}

function comboFunction() {
  // Get the checkbox
  var checkBox = document.getElementById("combo");
  // Get the output text
  var text = document.getElementById("combobox");

  // If the checkbox is checked, display the output text
  if (checkBox.checked == true) {
    text.style.display = "block";
  } else {
    text.style.display = "none";
  }
}

function recipeFunction() {
  // Get the checkbox
  var checkBox = document.getElementById("recipe");
  // Get the output text
  var text = document.getElementById("recipebox");

  // If the checkbox is checked, display the output text
  if (checkBox.checked == true) {
    text.style.display = "block";
  } else {
    text.style.display = "none";
  }
}


jQuery(document).ready(function(){
  jQuery(".summary-box-item-list tr.selected").click(function(){
    jQuery(".toogle-box").animate({
      width: "toggle"
    });
  });
});
  

